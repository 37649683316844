import React, { useEffect, useState } from "react"
import clsx from "clsx"
import Layout from "../components/layout"
import { Seo } from "../components/seo"
import { DirectSecp256k1HdWallet } from "@cosmjs/proto-signing"
import { Random, Ed25519, Bip39, stringToPath, Sha256 } from "@cosmjs/crypto"
import * as encoding from "@cosmjs/encoding"
import Code from "../components/Code"
import * as mainStyles from "../styles/index.module.scss"

export const Head = ({ location }) => (
  <Seo title="Key generator" pathname={location.pathname} />
)

const defaultPath = "m/44'/118'/0'/0/a"
const defaultPrefix = "cosmos"

const Generate = () => {
  const [path, setPath] = useState(defaultPath)
  const [prefix, setPrefix] = useState(defaultPrefix)
  const [mnemonic, setMnemonic] = useState(null)
  const [keyPair, setKeyPair] = useState(null)

  const [address, setAddress] = useState("")

  useEffect(() => {
    generateKeys()
  }, [])

  const generateKeys = async () => {
    const seed = Random.getBytes(32)
    const keyPair = await Ed25519.makeKeypair(seed)
    setKeyPair(keyPair)
    const mnemonic = Bip39.encode(Random.getBytes(32)).toString()
    setMnemonic(mnemonic)
  }

  const regenerateDerivations = async () => {
    if (!mnemonic) return
    try {
      const hdPath = stringToPath(path.replace("a", "1"))
      const wallet = await DirectSecp256k1HdWallet.fromMnemonic(mnemonic, {
        hdPaths: [hdPath],
        prefix: prefix,
      })
      const [{ address }] = await wallet.getAccounts()
      setAddress(address)
    } catch (e) {
      setAddress("")
    }
  }

  useEffect(() => {
    regenerateDerivations()
  }, [path, prefix, mnemonic])

  return (
    <Layout home>
      <h1>Key generator</h1>
      <p>
        Note: All these keys are generated in your browser. No information is
        sent anywhere or pulled from a third party.
      </p>
      <div
        className={mainStyles.flexRow}
        style={{ justifyContent: "space-between", alignItems: "flex-end" }}
      >
        <h2>Wallet generator</h2>
        <div>
          <button onClick={() => generateKeys()}>Generate new keys</button>
        </div>
      </div>
      <div className={clsx(mainStyles.gridRow)}>
        <div className={clsx(mainStyles.gridColXxs12, mainStyles.gridColM6)}>
          <label htmlFor="prefix">Prefix</label>
          <input
            id="prefix"
            value={prefix}
            onChange={e => setPrefix(e.target.value)}
          />
        </div>
        <div className={clsx(mainStyles.gridColXxs12, mainStyles.gridColM6)}>
          <label htmlFor="path">Prefix</label>
          <input
            id="path"
            value={path}
            onChange={e => setPath(e.target.value)}
          />
        </div>
      </div>
      <label htmlFor="address">Wallet address</label>
      <Code id="address">{address}</Code>
      <label htmlFor="mnemonic">Mnemonic</label>
      <Code id="mnemonic">{mnemonic}</Code>
      {keyPair ? (
        <React.Fragment>
          <div
            className={mainStyles.flexRow}
            style={{ justifyContent: "space-between", alignItems: "flex-end" }}
          >
            <h2>Validator key</h2>
            <div>
              <button onClick={() => generateKeys()}>Generate new keys</button>
            </div>
          </div>
          <label htmlFor="valkey">Private validator key</label>
          <Code id="valkey">{`{
  "address": "${encoding
    .toHex(new Sha256(keyPair.pubkey).digest().slice(0, 20))
    .toUpperCase()}",
  "pub_key": {
    "type": "tendermint/PubKeyEd25519",
    "value": "${encoding.toBase64(keyPair.pubkey)}"
  },
  "priv_key": {
    "type": "tendermint/PrivKeyEd25519",
    "value": "${encoding.toBase64(keyPair.toLibsodiumPrivkey())}"
  }
}`}</Code>
          <div
            className={mainStyles.flexRow}
            style={{ justifyContent: "space-between", alignItems: "flex-end" }}
          >
            <h2>Node key</h2>
            <div>
              <button onClick={() => generateKeys()}>Generate new keys</button>
            </div>
          </div>
          <label htmlFor="nodeaddress">Node address</label>
          <Code id="nodeddress">{`${encoding.toHex(
            new Sha256(keyPair.pubkey).digest().slice(0, 20)
          )}`}</Code>
          <label htmlFor="nodekey">Node key</label>
          <Code id="nodekey">{`{
  "priv_key": {
    "type": "tendermint/PrivKeyEd25519",
    "value": "${encoding.toBase64(keyPair.toLibsodiumPrivkey())}"
  }
}`}</Code>
        </React.Fragment>
      ) : null}
    </Layout>
  )
}

export default Generate
